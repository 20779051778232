import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../Footer/Footer";

const Forgot_password = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [message, setMessage] = useState("");  // For success messages
  const [error, setError] = useState("");  // For error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessage("please wait we are sending email..!")
    try {
      const response = await axios.post("https://localhost:8081/user_finding_email", null, {
        params: { email: formData.email }
      });
  
      if (response.status === 200) {
        setMessage("Password reset email sent. Please check your inbox.");
        setError("");  // Clear any previous error messages
      } else {
        setError("User not found.");
        setMessage("");  // Clear any previous success messages
      }
    } catch (error) {
      // Display error message from response if available
      const errorMessage = error.response?.data?.message || "Error occurred. Please try again.";
      setError(errorMessage);
      setMessage("");  // Clear any previous success messages
    }
  };
  

  return (
    <>
      <div className="main-container">
        <div id="particles-js" className="snow"></div>

        <main>
          <div className="left-side"></div>

          <div className="right-side">
            <form onSubmit={handleSubmit}>
              <div className="or">OR</div>

              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <button type="submit" className="login-btn">
                Submit
              </button>

              <div className="links">
                <Link to="/login">Already have an Account?</Link>
                
               
                <Link to="/register">Register</Link>
              </div>
              <br />
               <p className="error">{error}</p>
              <p className="message">{message}</p>
            </form>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Forgot_password;
