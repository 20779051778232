import React, { useState } from "react";
import "./r.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    try {
      const response = await axios.post(
        "https://localhost:8081/finding",
        formData
      );
  
      if (response.status === 200) {
        // Extract token and email from the response body
        const token = response.data.token;
        const emailReceiving = response.data.email;
        navigate("/")
        console.log("Received Token:", token); // Log token for debugging
        console.log("Received Email:", emailReceiving);
        localStorage.setItem("authToken", token);
        localStorage.setItem("email", emailReceiving);
        
        // Refresh the page after a successful login
        window.location.reload(); // This will reload the current page
      } else {
        setError("User not found.");
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
      setError("Invalid Email and password.");
    }
  };
  

  return (
    <>
      <div className="main-container">
        <div id="particles-js" className="snow"></div>

        <main>
          <div className="left-side"></div>

          <div className="right-side">
            <form onSubmit={handleSubmit}>
              <div className="or">OR</div>

              <label htmlFor="email">Email</label>
              <input
                type="text"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <label htmlFor="password">Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <p>{error}</p>

              <button type="submit" className="login-btn">
                Sign in
              </button>
              <div className="links">
                <Link to="/forgot">Forgot password?</Link>
                <Link to="/login">Do not have an account?</Link>
              </div>
            </form>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Register;
