import React, { useState } from "react";
import "./Contact.css"; // Make sure to create this CSS file with the necessary styles
import axios from "axios";

const CommentPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    text: "",
  });
  const [message, setMessage] = useState("");
  const [email,setEmail] = useState("chandansharma57@gmail.com")

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Please wait we are sending your message..!")
    try {
      const response = await axios.post(
        "https://13.60.145.223:8081/sending_email",
        formData
      );
      if (response.status === 200) {
        setMessage(
          `thanks for Sending Message.We will reply you shortly ,Thank you`
        );
        setTimeout(() => {
          setMessage(
            ""
          );
        }, 2000);

        setFormData({
          name: "",
          email: "",
          text: "",
        });
      } else {
        setMessage("failed to send message !");
      }
    } catch (error) {
      console.log(error);
      setMessage("Server error[ here is the Chandan's Whatsapp contact you can join through whatsapp :- 9845427041 ]")
    }
  };

  return (
    <div className="main-body">
      <div className="main">
        <div className="bio">
          <img
            src="https://w7.pngwing.com/pngs/505/888/png-transparent-coffee-splash-drink-beverage-caffeine.png"
            alt="Profile"
            className="profile-img"
          />
          <h3 className="header">Chandan Sharma</h3>
          <p>Java developer and Mern developer</p>
          <a href="https://twitter.com/alexboffey" className="bio-link">
            <i className="fa fa-twitter"></i> Twitter
          </a>
          <a href="https://github.com/alexboffey" className="bio-link">
            <i className="fa fa-github"></i> Github
          </a>
          <a href="https://codepen.io/alexboffey/" className="bio-link">
            <i className="fa fa-codepen"></i> CodePen
          </a>
        </div>

        <div className="contact">
          <form id="form" onSubmit={handleSubmit} className="fields">
            <legend className="header">Have a Coffee With Me,</legend>
            <fieldset>
              <label
                htmlFor="name-input"
                className="fa fa-user"
                aria-hidden="true"
              ></label>
              <input
                type="text"
                placeholder="Your name..."
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </fieldset>
            <fieldset>
              <label
                htmlFor="email-input"
                className="fa fa-envelope"
                aria-hidden="true"
              ></label>
              <input
                type="email"
                placeholder="Your email..."
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </fieldset>
            <fieldset>
              <label
                htmlFor="message-input"
                className="fa fa-comment"
                aria-hidden="true"
              ></label>
              <textarea
                placeholder="Your Message..."
                id="message"
                name="text"
                value={formData.text}
                onChange={handleChange}
                required
              />
            </fieldset>
            <fieldset>
              <button type="submit" id="btn-submit">
                Send
              </button>
            </fieldset>
            <p>{message}</p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommentPage;
