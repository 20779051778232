import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./NewsDetails.css"; // Import the CSS file for styling
import Footer from "../Footer/Footer";

const NewsDetails = () => {
  const location = useLocation();
  const { post } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      navigate("/register");
    }
  }, [navigate]);

  if (!post) {
    return <div>No post data available</div>;
  }

  return (
    <div className="news">
      <div className="news-details-container">
        <div className="news-details-header">
          <h1 className="news-details-title">{post.title}</h1>
          <div className="news-details-meta">
            <p className="news-details-author">
              By {post.author || "Unknown Author"}
            </p>
            <p className="news-details-date">
              Published on: {new Date(post.publishedAt).toLocaleDateString()}
            </p>
          </div>
        </div>

        <div className="news-details-content">
          <div className="news-details-image-container">
            <img
              src={post.urlToImage || "default-image-url.jpg"}
              alt="Post"
              className="news-details-image"
            />
          </div>
          <div className="news-details-text">
            <p className="news-details-description">
              {post.description || "No description available."}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsDetails;
