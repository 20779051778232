import React from "react";
import "./Loader.css"; // Create a CSS file for styling
import Footer from "../Footer/Footer";

const Loader = () => {
  return (
    <>

    <div className="loader-container">
      <div className="spinner"></div>
    </div>
    <Footer/>
    </>
  );
};

export default Loader;
