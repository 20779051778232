import React from "react";
import "./grid.css";
const Natural_habits = () => {
  return (
    <div className="main-containerr">
      <header class="container">
        <h1 class="main-title">My Natural Habits</h1>
        <p class="sub-title">
          At Natural Habits, we celebrate the beauty of simplicity. Our brand is
          rooted in the everyday rituals that connect us to nature and
          ourselves. From sustainable products to mindful practices, we invite
          you to discover a lifestyle that harmonizes with the world around you.
          Explore our collection and make conscious choices that reflect your
          true essence.
        </p>
      </header>
      <div class="content container">
        <div class="box box1">
          <div class="category">Forex Trader</div>
          <h3 class="title">The Benefits Of Forex Trading</h3>
        </div>
        <div class="box box2">
          <div class="category">Coffee Roasting</div>
          <h3 class="title">Boost Your Energy Naturally With Coffee.</h3>
        </div>
        <div class="box box3">
          <div class="category">Self-care</div>
          <h3 class="title">Better Sleep Strategies</h3>
        </div>
        <div class="box box4">
          <div class="category">Fitness Care</div>
          <h3 class="title">More Proritize in Healt Care.</h3>
        </div>
        <div class="box box5">
          <div class="category">Self-care</div>
          <h3 class="title">Mindful Living</h3>
        </div>
        <div class="box box6">
          <div class="category">Fashion</div>
          <h3 class="title">Sustainable Fashion</h3>
        </div>
      </div>
    </div>
  );
};

export default Natural_habits;
