import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Navbar from "./component/navbar";

import Users from "./component/forms/getdata";
import Home from "./OverlayImage/Home";
import Register from "./component/register/Register";
import Sign from "./component/register/sign";
import Forgot_passwod from "./component/register/Forgot-passwod";
import New_passwod from "./component/register/newpassword";
import News from "./component/news/News";
import NewDetails from "./component/news/NewDetails";
import Userprofile_v from "./userprofile/userprofile";


import ChatBox from "./massagingbox/bubble";
import AdminPanel from "./admindashboard/massagereply";

function App() {
  const [auth, setAuth] = useState(false);

  const authentication = async () => {
    const token = localStorage.getItem("authToken");
    setAuth(!!token);
  };

  useEffect(() => {
    authentication();
  }, []); // Ensuring the effect runs only once when the component mounts

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Sign />} />
          <Route path="/forgot" element={<Forgot_passwod />} />
          <Route path="/new_pass" element={<New_passwod />} />
          <Route path="/news" element={<News />} />
          <Route path="/news_details" element={<NewDetails />} />
          <Route path="/admin" element={<AdminPanel />} />
          {auth ? (
            <Route path="/profile" element={<Userprofile_v />} />
          ) : (
            <Route path="/profile" element={<Navigate to="/register" />} />
          )}
          <Route path="/user_profile" element={<Users />} /> 
        </Routes>
        <ChatBox/>
      </BrowserRouter>
    </>
  );
}

export default App;
