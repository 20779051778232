import React, { useState, useEffect, useRef } from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import "./Bubble.css";

const ChatBox = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [stompClient, setStompClient] = useState(null);
  const [connected, setConnected] = useState(false);
  const messagesEndRef = useRef(null); // For auto-scrolling to the latest message

  // Initialize WebSocket connection
  useEffect(() => {
    const socket = new SockJS("https://localhost:8081/chat-websocket");
    const client = Stomp.over(socket);

    client.connect(
      {},
      () => {
        console.log("Connected to WebSocket (Client)");
        setConnected(true);

        // Subscribe to the broadcast topic
        client.subscribe("/topic/messages", (message) => {
          const receivedMessage = JSON.parse(message.body);
          console.log("Broadcasted message received:", receivedMessage);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: receivedMessage.content, sender: receivedMessage.sender },
          ]);
        });

        setStompClient(client);
      },
      (error) => {
        console.error("WebSocket connection error:", error);
        setConnected(false);
      }
    );

    return () => {
      if (client) client.disconnect();
    };
  }, []);

  // Scroll to the latest message
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Toggle chat box visibility
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
    if (!isVisible) {
      document.body.classList.add("blur-background");
    } else {
      document.body.classList.remove("blur-background");
    }
  };

  // Handle typing in the input field
  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  // Handle sending messages
  const handleMessageSubmit = (e) => {
    e.preventDefault();
    if (!userMessage.trim()) return;

    setLoading(true);

    // Publish the user's message to the backend
   
    if (stompClient && connected) {
      let username = localStorage.getItem("username");
    
      // If username is not found, generate a default username or ask the user to input one
      if (!username) {
        // Example of automatically generating a default username
        username = "user_" + Math.floor(Math.random() * 1000);  // You can modify this logic
        localStorage.setItem("username", username);  // Store the generated username in localStorage
        console.log("Generated and stored new username:", username);
      }
    
      const chatMessage = {
        sender: username, // Sender is the retrieved or newly generated username
        content: userMessage,
      };
    
      stompClient.send("/app/chat", {}, JSON.stringify(chatMessage));
    
      // Simulate admin response for demonstration purposes
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages]);
        setLoading(false);
      }, 1000);
    } else {
      console.error("STOMP client is not connected.");
      setLoading(false); // Stop loading if connection is not ready
    }
    

    // Clear the input field after sending
    setUserMessage("");
  };

  return (
    <div className="chat-box-container">
      {isVisible && (
        <div className="popup-overlay" onClick={toggleVisibility}></div>
      )}

      <div className="chat-box-btn" onClick={toggleVisibility}>
        <p className="chat-box-txt">Chat with Chandan live!</p>
      </div>

      <div className={`chat-box ${isVisible ? "show" : ""}`}>
        <div className="chat-box-header">
          <h2>Chat with Chandan </h2>
          <button className="chat-box-closeBtn" onClick={toggleVisibility}>
            ×
          </button>
        </div>

        <div className="chat-box-messages">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${
                msg.sender === "User" ? "user-message" : "admin-message"
              }`}
            >
              <p>
                <strong>{msg.sender}:</strong> {msg.text}
              </p>
            </div>
          ))}
          {loading && <p className="chat-message bot-message">...</p>}
          <div ref={messagesEndRef} />
        </div>

        <form onSubmit={handleMessageSubmit} className="chat-box-form">
          <input
            type="text"
            className="chat-box-input"
            value={userMessage}
            onChange={handleMessageChange}
            placeholder="Type your message"
            required
          />
          <button type="submit" className="chat-box-sendBtn">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBox;
