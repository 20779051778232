import React, { useEffect } from "react";
import "./projects.css";
import ScrollReveal from "scrollreveal";

const Projects = () => {
  useEffect(() => {
    // ScrollReveal JS
    ScrollReveal({ distance: "50px" });

    ScrollReveal().reveal(".title", {
      delay: 200,
      easing: "ease-in",
      origin: "top",
      distance: "70px",
      duration: 900,
    });

    ScrollReveal().reveal(".description", {
      delay: 1000,
      easing: "ease-in",
      origin: "top",
      distance: "30px",
      duration: 1000,
    });

    ScrollReveal().reveal(".btn", {
      delay: 2000,
      easing: "ease-in-out",
      duration: 1000,
    });

    ScrollReveal().reveal(".card-container", {
      delay: 400,
      easing: "ease-in-out",
      origin: "right",
      distance: "800px",
      duration: 2500,
    });

    ScrollReveal().reveal(".gradient-line", {
      delay: 200,
      easing: "ease",
      origin: "left",
      distance: "1800px",
      duration: 3600,
    });

    ScrollReveal().reveal(".featured-title", {
      delay: 400,
      easing: "ease-in",
      origin: "right",
      distance: "200px",
      duration: 1400,
    });

    ScrollReveal().reveal(".item", {
      delay: 1200,
      interval: 200,
      origin: "bottom",
      easing: "ease-in-out",
      duration: 400,
    });

    // Lightbox functionality
    const galleryImages = document.querySelectorAll(".item img");
    const lightbox = document.querySelector(".lightbox");
    const lightboxImage = document.querySelector(".img-container img");
    const lightboxTitle = document.querySelector(".img-container p");
    const prevBtn = document.querySelector(".prev");
    const nextBtn = document.querySelector(".next");
    const body = document.querySelector("body");

    let currentIndex;

    galleryImages.forEach((img, index) => {
      img.addEventListener("click", function () {
        currentIndex = index;
        updateLightbox();
        lightbox.style.display = "flex";
        body.classList.add("prevent-background-scroll");
      });
    });

    lightbox.addEventListener("click", function (e) {
      if (e.target === lightbox) {
        lightbox.style.display = "none";
        body.classList.remove("prevent-background-scroll");
      }
    });

    prevBtn.addEventListener("click", function () {
      currentIndex =
        (currentIndex - 1 + galleryImages.length) % galleryImages.length;
      updateLightbox();
    });

    nextBtn.addEventListener("click", function () {
      currentIndex = (currentIndex + 1) % galleryImages.length;
      updateLightbox();
    });

    function updateLightbox() {
      const currentImage = galleryImages[currentIndex];
      lightboxImage.src = currentImage.src;
      lightboxTitle.textContent = currentImage.alt;
    }
  }, []);

  return (
    <div>
      <section className="intro">
        <div className="intro-content">
          <h1 className="title">Hey ! I am Chandan Sharma</h1>
          <p className="description">
            <span className="collage-art">Hey, my name is Chandan Sharma,</span>{" "}
            and I am a MERN developer and Java developer. I have completed
            numerous projects, which are listed below for you to check out.
             Push the boundaries of your artistic expression and
            create a masterpiece with the help of AI! Join this artistic
            journey, where your imagination meets the limitless possibilities of
            AI.
          </p>
          <a href="#featured" className="btn">
            Download my Resume 
          </a>
        </div>

        <div className="card-container">
          <div style={{ "--r": -15, "--y": 40, "--x": 50 }} className="card">
            <img
              src="https://scontent.fktm21-2.fna.fbcdn.net/v/t39.30808-6/432831813_1455743335013168_8932581147127610666_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=GZf__za4hUgQ7kNvgHB6LVQ&_nc_ht=scontent.fktm21-2.fna&_nc_gid=Ah-INbW7LnEOTLGoVFh_7fN&oh=00_AYBwY_sTZZuWiMbe6i1HpppvbTf0e5jv2gGmPQEbbAJ0zg&oe=67100A27"
              alt=""
            />
          </div>
          <div style={{ "--r": 5, "--y": -30, "--x": 30 }} className="card">
            <img
              src="https://scontent.fktm21-2.fna.fbcdn.net/v/t39.30808-6/444764360_1488122901775211_957779013265651586_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=xcNTJX6_VcoQ7kNvgFNi7kc&_nc_ht=scontent.fktm21-2.fna&_nc_gid=AEC75ho6Wita_rCdVAo4538&oh=00_AYBOjVcB6yrMl-8kvGdMYhVJELfIZuzgFKFq2oTqxy7B0A&oe=671006D0"
              alt=""
            />
          </div>
          <div style={{ "--r": 15, "--y": 50, "--x": 0 }} className="card">
            <img
              src="https://scontent.fktm21-2.fna.fbcdn.net/v/t39.30808-6/428688299_1438460390074796_3043100674254529561_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=833d8c&_nc_ohc=VfjU5Wf5MUQQ7kNvgFVqfln&_nc_ht=scontent.fktm21-2.fna&_nc_gid=AXvOkJn0qoBqGcCX-hOatBO&oh=00_AYBZ8p7i_gdlzf5fd9JetENkKL1pxrvS4k1HDsUARwYEOw&oe=67101A20"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="featured" id="featured">
        <div className="gradient-line"></div>
        <h1 className="featured-title">Checkout My Projects</h1>
        <div className="grid-area">
          <div className="item art-one">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/a7081089-be4b-42e3-bfe9-691dbac43603"
              alt="A Good Boy"
            />
          </div>

          <div className="item art-two">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/bd3d4b31-03c6-47a2-9d1c-c355cada1512"
              alt="City Life"
            />
          </div>

          <div className="item art-three">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/2e280db7-6bb2-4acb-8aa0-c23d4906a1d5"
              alt="An Ode to Mushrooms"
            />
          </div>

          <div className="item art-four">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/e910c96c-eaed-4291-937d-b38d7cd0f0db"
              alt="A Woman in Complexity"
            />
          </div>

          <div className="item art-five">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/8daeb10c-87c4-4b37-baa1-43559718ed05"
              alt="Steampunk"
            />
          </div>

          <div className="item art-six">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/533fc7a4-e1f1-4683-9bcd-1f1386552a67"
              alt="Dragon in Silks"
            />
          </div>

          <div className="item art-seven">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/14efe3d5-940b-47d7-b9d3-c9b882ff905f"
              alt="Cut and Paste Face"
            />
          </div>

          <div className="item art-eight">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/037f6971-f851-48fa-b793-b8eac9b39865"
              alt="Dolphins in the Sea"
            />
          </div>

          <div className="item art-nine">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/5a118a78-ca7c-4878-995d-49ceb06c754a"
              alt="The Raven's Song"
            />
          </div>

          <div className="item art-ten">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/bc8cdd3a-784e-43ff-9363-661ee9becab2"
              alt="Haunted House"
            />
          </div>

          <div className="item art-eleven">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/ef40ae8d-3ece-4d33-8974-9e16ade79784"
              alt="The World of Butterflies"
            />
          </div>

          <div className="item art-twelve">
            <img
              src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/f5d273e4-30e1-42ba-b4b2-52c7e170bc8c"
              alt="Enchanted Forest"
            />
          </div>
        </div>

        <div className="lightbox">
          <div className="lightbox-content">
            <div className="prev">◀</div>
            <div className="img-container">
              <img src="" alt="" />
              <p></p>
            </div>
            <div className="next">▶</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
