import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }
  
    try {
      const response = await axios.post("https://localhost:8081/api/reset_password", null, {
        params: {
          token,          // The token from the URL query params
          newPassword,    // The new password the user sets
        }
      });
  
      if (response.status === 200) {
        setMessage("Password has been reset successfully.");
      } else {
        setMessage("Error resetting password.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  };
  
  

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="newPassword">New Password:</label>
        <input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      <p>{message}</p> {/* Display the message here */}
      <Footer />
    </div>
  );
};

export default ResetPassword;
