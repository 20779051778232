import React, { useState, useEffect } from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import "./AdminPanel.css"; // Importing custom styles
import Footer from "../component/Footer/Footer";

const AdminPanel = () => {
  const [stompClient, setStompClient] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // Store received messages
  const [replyTo, setReplyTo] = useState(""); // Store the username to reply to
  const [sendingMessage, setSendingMessage] = useState(false); // Flag to track message sending state

  // Establish WebSocket connection
  useEffect(() => {
    const socket = new SockJS("https://localhost:8081/chat-websocket");
    const client = Stomp.over(socket);

    client.connect(
      {},
      () => {
        console.log("Connected to WebSocket");

        // Subscribe to messages from clients
        client.subscribe("/topic/messages", (receivedMessage) => {
          const messageBody = JSON.parse(receivedMessage.body);

          // Prevent adding duplicate messages to the state
          setMessages((prevMessages) => {
            // If the message is not already in the state, add it
            if (!prevMessages.find((msg) => msg.content === messageBody.content && msg.sender === messageBody.sender)) {
              return [...prevMessages, messageBody];
            }
            return prevMessages;
          });
        });

        setStompClient(client);
      },
      (error) => {
        console.error("WebSocket connection failed:", error);
      }
    );

    return () => {
      if (client) client.disconnect();
    };
  }, []);

  // Handle sending messages
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!message.trim() || !stompClient || sendingMessage) {
      console.error("Message is empty or STOMP client is not connected, or message is already being sent.");
      return;
    }

    setSendingMessage(true); // Set flag to indicate the message is being sent

    const adminMessage = {
      sender: "Admin",
      content: message,
      recipient: replyTo || "Broadcast", // Send to a specific user or broadcast
    };

    // Send the message via STOMP (WebSocket)
    stompClient.send("/app/chat", {}, JSON.stringify(adminMessage));

    // Add the message to the state only once after sending
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "Admin", content: message, recipient: replyTo || "Broadcast" },
    ]);

    // Clear the message input and reply context after sending
    setMessage("");
    setReplyTo("");

    setSendingMessage(false); // Reset the flag after message is sent
  };

  const handleReply = (sender) => {
    setReplyTo(sender);
  };

  return (
    <>
      <div className="admin-panel-container">
        <h2 className="admin-panel-header">Admin Panel</h2>

        <div className="admin-message-input">
          {replyTo && <p className="admin-reply-to">Replying to: {replyTo}</p>}
          <textarea
            className="admin-message-textarea"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button className="admin-send-button" onClick={handleSendMessage}>
            Send Message
          </button>
        </div>

        <div className="admin-received-messages">
          <h3 className="admin-received-messages-header">Received Messages</h3>
          <ul className="admin-message-list">
            {messages.map((msg, index) => (
              <li
                key={index}
                className="admin-message-item"
                onClick={() => handleReply(msg.sender)}
              >
                <strong>{msg.sender}:</strong> {msg.content}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminPanel;
