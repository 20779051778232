import React from "react";
import './home.css';
import Uploading from "../component/productsUploading/Uploading";
import ProductList from "../component/productsUploading/gettingpro";
import image1 from './chandan2.jpg'
import Natural_habits from "../component/slider/Habits/Natural_habits";
import Projects from "../component/projects/Projects";

import CommentPage from "../component/forms/Contact";
import Footer from "../component/Footer/Footer";
const Home = () => {
  return (
    <div>
      <section class="showcase">
        {/* <img
          src='https://scontent.fktm21-1.fna.fbcdn.net/v/t39.30808-6/453148934_1527800327807468_6940421455740120482_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=833d8c&_nc_ohc=wrHJCEJc0j4Q7kNvgGHhqbl&_nc_ht=scontent.fktm21-1.fna&oh=00_AYDUBaYEnmsoeI1lfbwBmDwPKUA6YoV9jabcNgdYPT87Lw&oe=66BC1E7F'
          alt="Picture"
        /> */}
        <img src={image1} alt="" />
        <div class="overlay">
          <h2>Chandan Sharma</h2>
          <p>
            Google IO Extended , Kathmandu Maitighar,St-Xaviour College,
          </p>
        </div>
      </section>
      <Natural_habits/>
      <Projects/>
    
    <CommentPage/>
    <Footer/>
      {/* <Chandan/> */}
      {/* <Uploading/>
      <ProductList/> */}
    </div>
  );
};

export default Home;
