import React, { useState, useEffect } from "react";
import "./UserProfile.css"; // Optional: Create a CSS file for styling if needed
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";

const UserProfile = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]); // Initialize as an empty array to handle multiple users
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Assuming the token is stored in localStorage
        const response = await axios.get("https://13.60.145.223:8081/finding_user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setUsers(response.data); // Assuming response.data is an array of users
          console.log(response.data);
        } else {
          setError("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("An error occurred while fetching user details");
      }
    };

    fetchUserDetails();
  }, []);

  const handleDelete = async (username) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.delete(`https://13.60.145.223:8081/user/${username}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        alert("User deleted successfully");
        setUsers(users.filter(user => user.username !== username)); // Remove the deleted user from the list
      } else {
        alert("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    alert("Logged out successfully");
    navigate("/sign");
  };

  if (error) {
    return <div className="user-profile-container"><p>{error}</p></div>;
  }

  if (users.length === 0) {
    return <div className="user-profile-container"><p>Loading...</p></div>;
  }

  return (
    <>
      <div className="user-profile-container">
        <h1>User Profiles</h1>
        {users.map((user, index) => (
          <div className="profile-details" key={index}>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>Email:</strong> {user.email}</p>
            {/* Add more user details here */}
            <div className="button-container">
              <button className="logout-btn" onClick={handleLogout}>Logout</button>
              <button className="delete-btn" onClick={() => handleDelete(user.username)}>Delete Profile</button>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default UserProfile;
