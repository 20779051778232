import React, { useState } from "react";
import "./r.css";
import axios from "axios";
import Footer from "../Footer/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Sign = () => {
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [sucess, setsucess] = useState("");

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://localhost:8081/register",
        formData
      );
      if (response.status === 200) {
        // Store username in localStorage after successful registration
        localStorage.setItem("username", formData.username);
        setsucess("Registration successful!");
        
        // Redirect to login page after successful registration
        Navigate("/login");
      } else {
        setsucess("Registration failed!");
      }
    } catch (error) {
      console.log(error);
      setsucess("Registration failed!");
    }
  };

  return (
    <>
      <div className="main-container">
        <div id="particles-js" className="snow"></div>

        <main>
          <div className="left-side"></div>

          <div className="right-side">
            <form onSubmit={handlesubmit}>
              <div className="or">OR</div>

              <label htmlFor="username">Username</label>
              <input
                type="text"
                placeholder="Enter username"
                name="username"
                value={formData.username}
                onChange={handlechange}
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="text"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handlechange}
                required
              />

              <label htmlFor="password">Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                value={formData.password}
                onChange={handlechange}
                required
              />

              <p>{sucess}</p>

              <button type="submit" className="login-btn">
                Sign up
              </button>

              <div className="links">
                <Link to="/forgot">Forgot password?</Link>
                <Link to="/login">Already have an Account?</Link>
              </div>
            </form>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Sign;
