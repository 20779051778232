import React, { useState } from "react";
import "./footer.css";
import axios from "axios";

const Footer = () => {
  const [data, setData] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://13.60.145.223:8081/saving_email", formData);
      if (response.status === 200) {
        setData("Thanks for subscribing!");
        setFormData({email: ""})
      } else {
        setData("Error sending email.");
      }
    } catch (error) {
      setData("Error sending email.");
    }
  };

  return (
    <>
      <form method="post" onSubmit={handleSubmit}>
        <div className="footer-container">
          <footer className="footer">
            <div className="footer-content">
              <div className="footer-top">
                <div className="footer-logo-details">
                  <i className="fab fa-slack footer-logo-icon"></i>
                  <span className="footer-logo-name">Chandan Sharma</span>
                </div>
                <div className="footer-media-icons">
                  <a href="#" className="footer-media-icon">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="footer-media-icon">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="footer-media-icon">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" className="footer-media-icon">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#" className="footer-media-icon">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
              <div className="footer-link-boxes">
                <ul className="footer-box">
                  <li className="footer-link-name">Company</li>
                  <li>
                    <a href="#" className="footer-link">Home</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Contact us</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">About us</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Get started</a>
                  </li>
                </ul>
                <ul className="footer-box">
                  <li className="footer-link-name">Services</li>
                  <li>
                    <a href="#" className="footer-link">App design</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Web design</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Logo design</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Banner design</a>
                  </li>
                </ul>
                <ul className="footer-box">
                  <li className="footer-link-name">Account</li>
                  <li>
                    <a href="#" className="footer-link">Profile</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">My account</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Preferences</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link">Purchase</a>
                  </li>
                </ul>

                <ul className="footer-box footer-input-box">
                  <li className="footer-link-name">Subscribe</li>
                  <li>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="footer-input"
                    />
                  </li>
                  <li>
                   
                    {data ? (
                      <p className="footer-success-message">{data}</p>
                    ) : (
                      <input type="submit" value="Subscribe" className="footer-button" />
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer-bottom-details">
              <div className="footer-bottom-text">
                <span className="footer-copyright-text">
                  Copyright © 2024 <a href="#" className="footer-link">Chandan Sharma</a>. All rights reserved
                </span>
                <span className="footer-policy-terms">
                  <a href="#" className="footer-link">Privacy policy</a>
                  <a href="#" className="footer-link">Terms & condition</a>
                </span>
              </div>
            </div>
          </footer>
        </div>
      </form>
    </>
  );
};

export default Footer;
