import React, { useEffect, useState } from "react";
import Footer from "../component/Footer/Footer";
import axios from "axios";
import "./userprofile_v.css"; // Updated CSS file name
import { useNavigate } from "react-router-dom";
const Userprofile_v = () => {
  const [userData, setUserData] = useState({ email: "", username: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const Navigate = useNavigate();
  const fetchUserData = async () => {
    setLoading(true);
    setError(null);
    try {
      const email = localStorage.getItem("email");

      if (!email) {
        throw new Error("No email found in localStorage");
       
      }

      const response = await axios.get(
        `https://localhost:8081/finding_user_by_email`,
        {
          params: { email },
        }
      );

      if (
        response.status === 200 &&
        response.data.email &&
        response.data.username
      ) {
        setUserData({
          email: response.data.email,
          username: response.data.username,
        });
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setError(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);




  const handleLogout = async () => {
    try {
      const email = localStorage.getItem("email");
      if (!email) {
        throw new Error("No email found in localStorage");
      }
  
      // Send the email as a query parameter in the DELETE request
      const response = await axios.delete(
        `https://localhost:8081/api/auth/logout`, 
        {
          params: { email }, // Pass email as query parameter
        }
      );
  
      if (response.status === 200) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
        Navigate("/register");
      } else {
        alert("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error.message);
      alert(`Error: ${error.message}`);
    }
  };
  
  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
    <br />
    <br />
      <div className="user-profile-v">
        <div className="user-profile-v__info">
          <h2 className="user-profile-v__title">User Profile</h2>
          <p className="user-profile-v__detail">
            <strong>Username:</strong>{" "}
            {userData.username || "Username not available"}
          </p>
          <p className="user-profile-v__detail">
            <strong>Email:</strong> {userData.email || "Email not available"}
          </p>
          <button className="user-profile-v__logout-btn" onClick={handleLogout}>
            Log out
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Userprofile_v;
