import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './news.css'; // Import the CSS file for styling
import Footer from '../Footer/Footer';
import { useNavigate } from "react-router-dom";
import Loader from './Loader';

const News = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [triggerSearch, setTriggerSearch] = useState(false);
  const resultsPerPage =20;

  const fetchPosts = async () => {
    setLoading(true); // Start loader before fetching data
    try {
      const response = await axios.get('https://newsapi.org/v2/everything?q=tesla&from=2024-10-29&sortBy=publishedAt&apiKey=4fc6671eea3f42bf9bcdae6008e925c8', {
        params: {
          q: searchQuery || 'tesla', // Use searchQuery if provided
          from: '2024-07-17',
          sortBy: 'publishedAt',
          apiKey: '4fc6671eea3f42bf9bcdae6008e925c8',
          page: currentPage,
          pageSize: resultsPerPage,
        },
      });
      console.log('API Response:', response.data); // Log the API response

      if (response.data && Array.isArray(response.data.articles)) {
        setPosts(response.data.articles);
        setTotalResults(response.data.totalResults);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false); // Stop loader after fetching data
    }
  };

  useEffect(() => {
    if (triggerSearch || searchQuery === '') {
      fetchPosts(); // Fetch posts when searchQuery or currentPage changes, or when search is triggered
      setTriggerSearch(false);
    }
  }, [currentPage, searchQuery, triggerSearch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page for new search
    setTriggerSearch(true); // Trigger search
  };

  const handlePageChange = (direction) => {
    setLoading(true); // Start loader when page changes
    setCurrentPage(prevPage => {
      const newPage = direction === 'next' ? Math.min(prevPage + 1, Math.ceil(totalResults / resultsPerPage)) : Math.max(prevPage - 1, 1);
      return newPage;
    });
  };

  if (loading) return <div className="loading"><Loader/></div>;
  if (error) return <div className="error">Error: {error.message}</div>;

  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const handleDetails = (post) => {
    navigate("/news_details", { state: { post } });
  }

  return (
    <>
      <div className="posts-page-container">
        <h2 className='latest'>Latest Update...</h2>

        <div className="search-container">
          <input
          className='search-query'
            type="text"
            placeholder="Search news..."
            value={searchQuery}
            onChange={handleSearchChange} // Update search query on input change
          />
          <button onClick={handleSearch} className='search-btn'>Search</button> {/* Button to trigger search */}
        </div>

        <div className="posts-page__posts">
          {posts.length > 0 ? (
             posts.map((post, index) => (
              <div key={index} onClick={() => handleDetails(post)} className={`posts-page__item ${index === 0 ? 'posts-page__item--main' : ''}`}>
                <div className="posts-page__image">
                  <img src={post.urlToImage || 'default-image-url.jpg'} alt="Post" />
                </div>
                <div className="posts-page__information">
                  <div className="posts-page__date">
                    {new Date(post.publishedAt).toLocaleDateString()}
                  </div>
                  <div className="posts-page__title">
                    <a href={post.url} target="_blank" rel="noopener noreferrer">{post.title}</a>
                  </div>
                  {post.author && (
                    <div className="posts-page__author">
                      <span className='author'>By {post.author}</span>
                    </div>
                  )}
                  <div className="posts-page__description">
                    {post.description}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-posts">No posts available</div>
          )}
        </div>

        <div className="pagination">
          <button
            className="pagination__button"
            onClick={() => handlePageChange('prev')}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="pagination__info">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="pagination__button"
            onClick={() => handlePageChange('next')}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
